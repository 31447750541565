import { graphql } from "gatsby"
import React, { useMemo } from "react"
import styled from "styled-components"
import getLocales from "utils/getLocales"
import { HeaderOptions } from "components/Header/HeaderUI"
import FooterStrapi from "../components/Footer"
import HeaderStrapi from "../components/Header"
import SEO, { getSEOProps } from "../components/SEO"
import DynamicZone from "../components/Strapi/DynamicZone"
import BareLayout from "../layouts/Bare"
import { updateLocalizations } from "./DynamicPageTemplate"
import convertLocale from "../utils/convertLocale"

const Wrapper = styled.div`
  > :first-child {
    padding-top: 50px;
  }

  &.with-tp-banner,
  &.sticky-tp {
    > :first-child {
      padding-top: 90px;
    }
  }
`

interface IProps {
  data: {
    site: {
      siteMetadata: { siteUrl: string }
    }
    baseOffersEdges: {
      edges: {
        node: Strapi.SpecialOffer
      }
    }
    strapiSpecialoffers: Strapi.SpecialOffer
  }
  pageContext: {
    defaultLang: string
    slug: string
    pagePath?: string
    headerOptions?: HeaderOptions
  }
}

const SpecialOfferTemplate = (props: IProps) => {
  const { data, pageContext }: { data: any; pageContext: any } = props
  const { site, strapiSpecialoffers: specialoffer, baseOffersEdges } = data
  const baseOffers = baseOffersEdges.edges.map(({ node }) => node)
  const { content, SEO: SEOProps } = specialoffer
  const { locale, localizations = [] } = updateLocalizations({
    ...specialoffer,
    localizations: [
      ...specialoffer.localizations,
      { locale: specialoffer.locale, translated: specialoffer.translated },
    ],
    locale: pageContext.locale
      ? pageContext.locale.replace("/", "")
      : specialoffer.locale,
  })
  const otherLangs = getLocales(localizations)
  const nonStickyTrustPilotbanner =
    pageContext.headerOptions?.trustpilot &&
    !pageContext.headerOptions.trustpilot.sticky

  const stickyTrustpilot = Boolean(
    pageContext.headerOptions?.trustpilot?.sticky
  )

  const links = useMemo(() => {
    if (specialoffer.noSlug) {
      return baseOffers.map(offer => {
        const locale = offer.locale.toLowerCase()
        return {
          rel: "alternate",
          hrefLang: offer.locale.toLowerCase(),
          href: `${site.siteMetadata.siteUrl}/${locale}/parents/special-offer`,
        }
      })
    }
  }, [otherLangs, specialoffer])

  const baseOffer = (baseOffers || []).find(d => d.locale === locale)
  const canonical = baseOffer
    ? `${site.siteMetadata.siteUrl}/${
        locale === "en" ? "" : `${convertLocale(locale).toLowerCase()}/`
      }parents/special-offer`
    : `${site.siteMetadata.siteUrl}/${
        locale === "en" ? "" : `${convertLocale(locale).toLowerCase()}/`
      }parents`

  return (
    <BareLayout>
      <SEO
        {...getSEOProps(SEOProps)}
        lang={locale}
        links={links}
        canonical={canonical}
        titleTemplate="%s | Night Zookeeper"
      />
      <HeaderStrapi
        shouldHighlightLink={(l: string) => /parents|home/.test(l)}
        pagePath={pageContext.pagePath}
        availableLangs={pageContext.noCountrySelector ? [] : otherLangs}
        options={props.pageContext?.headerOptions}
        locale={locale}
      />
      <Wrapper
        className={`content-root ${
          nonStickyTrustPilotbanner ? "with-tp-banner" : ""
        } ${stickyTrustpilot ? "sticky-tp" : ""}`}
      >
        <DynamicZone content={content} />
      </Wrapper>
      <FooterStrapi />
    </BareLayout>
  )
}

export const query = graphql`
  query ($id: Int) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    baseOffersEdges: allStrapiSpecialoffers(filter: { noSlug: { eq: true } }) {
      edges {
        node {
          locale
          noSlug
          base_page {
            slug
          }
        }
      }
    }
    strapiSpecialoffers(strapiId: { eq: $id }) {
      locale
      translated
      localizations {
        locale
        translated
      }
      base_page {
        slug
      }
      content {
        ...BookHero
        ...ZooHero
        ...ParentProductHero
        ...TeacherHero
        ...ShopHero
        ...PartnersGroup
        ...ExplanationHero
        ...TitleSeparator
        ...EngagingBanner
        ...BigIconsHero
        ...RatingsHero
        ...HowItWorks
        ...QuoteHero
        ...PlansHero
        ...PlanHero
        ...QuotesCarousel
        ...TeacherCTAHero
        ...PageTitle
        ...FAQSection
        ...Partners
        ...Strapi_RichSection
        ...FixedBanner
        ...CTAStrip
        ...TrustpilotWidget
        __typename
      }
      slug
      noSlug
      SEO {
        title
        description
        url
        noIndex
        image {
          url
        }
        canonical
      }
    }
  }
`

export default SpecialOfferTemplate
